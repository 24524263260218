import React, {useEffect, useState} from 'react';
import {Link} from 'gatsby';


const RenderDiseasesList = ({ diseases, parentUrl = "", isMobile = false}) => {

    // use react hooks to create state in functional component
    // numberToShow is a number of diseases to render on the home page

    const [numberToShowOnHomePage, setNumberToShowOnHomePage] = useState(6);
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {

        if(isMobile && !initialized) {
            setNumberToShowOnHomePage(3);
            setInitialized(true);
        }

    });

    const renderDiseases = () => {

        // create a new array from an array of all diseases
        // a new array can't be bigger than numberToShow state

        if(diseases.length > 0) {

            let updatedDiseases = [];

            for(let i = 0; i < numberToShowOnHomePage; i++) {
                updatedDiseases.push(diseases[i])
            }

            // const updatedDiseases = [];
            if(updatedDiseases.length === numberToShowOnHomePage) {
                return updatedDiseases.map((item, id) => {
                    return <Link to={item.route} key={id} >{item.title}</Link>
                });

            } else return <div/>
        }
        else return <div/>

    };

    // load more function
    const loadMore = () => {

        const incrementValue = 2;

        if (diseases.length - numberToShowOnHomePage - incrementValue >= 0) {
            setNumberToShowOnHomePage(numberToShowOnHomePage + incrementValue)
        } else {
            setNumberToShowOnHomePage(diseases.length)
        }
    };

    return (
        <div>
            {renderDiseases(isMobile)}
            {numberToShowOnHomePage < diseases.length ? <a className="load-more" onClick={loadMore}>Meer</a> : <div />}
        </div>
    )
};

export default RenderDiseasesList;
