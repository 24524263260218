import React, {Component} from "react"
import {Alert, Col, Row, Spinner} from "react-bootstrap";
import ReactTags from "react-tag-autocomplete";
import {Link} from 'gatsby';

import {db, grav} from "../../../graphql/client.js";

import {gql} from 'apollo-boost'

import RenderDiseasesList from "../../RenderDiseasesList";
import MobilePopup from "../../MobilePopup";
import ResponsiveContainer from "../../ResponsiveContainer";
import {navigate} from "gatsby-link";


const TAGS_CLASS_NAMES = {
    root: 'symptom-tags',
    rootFocused: 'is-focused',
    selected: 'symptom-tags__selected',
    selectedTag: 'symptom-tags__selected-tag',
    selectedTagName: 'symptom-tags__selected-tag-name',
    search: 'symptom-tags__search',
    searchWrapper: 'symptom-tags__search-wrapper',
    searchInput: 'symptom-tags__search-input',
    suggestions: 'symptom-tags__suggestions',
    suggestionActive: 'is-active',
    suggestionDisabled: 'is-disabled'
};

const DISEASES_VISIBLE = 6;

const MessageContainer = (props) => {
    return <Alert variant="danger"><h5>Error</h5><p>{props.message}</p></Alert>;
};

class TagSearchHome extends Component {

    constructor(props) {

        super(props);

        this.state = {
            focused:false,
            loading: false,
            error: false,
            errorMessage: "",
            tags: [],
            suggestions: [],
            suggestion: false,
            symptoms:[],
            lastQuery: null,
            diseases: [],
            diseasesBySymptoms: [],
            diseaseData: {},
            diseasesVisible: DISEASES_VISIBLE,
            currentId: 1,
            showModal:false,
        }

        this.handleDiseaseContent = this.handleDiseaseContent.bind(this);
        this.handleDiseaseResponse = this.handleDiseaseResponse.bind(this);
        this.loadDiseases = this.loadDiseases.bind(this);

    }

    componentDidMount() {

        const {searchUrl, pages} = this.props;

        const diseases = this.shuffle(pages);

        this.setState({
            diseases,
            diseasesBySymptoms:diseases,
        });

        this.handleSearchAPI("", "search");

        document && document.addEventListener('keypress', event => {
            if(event.key === 'Enter' && this.state.tags.length > 0 && searchUrl !== null) navigate(searchUrl);
        });
    }

    /**
     * handle data when tag is removed
     * @param i
     */
    onDelete (i) {

        const tags = this.state.tags && this.state.tags.slice(0);
        tags.splice(i, 1);

        let suggestions = this.state.symptoms.length > 0 && this.state.symptoms.map((suggestion)=>{
            return (!tags.find(item => item.name === suggestion.name)) ? suggestion : {};
        });

        this.setState({ tags, suggestions });

        if(tags.length > 0) this.handleSearchAPI(tags, "symptoms");
        if(tags.length === 0) {
            this.handleSearchAPI();
            sessionStorage.removeItem("tags");
        }
    }
    /**
     * handle data when tag is added
     * @param tag
     */
    onAddition (tag) {

        const tags = [].concat(this.state.tags, tag);

        const diseaseData = [];

        if(tags.length > 0) this.handleSearchAPI(tags, "symptoms");
        if(tags.length === 0) this.handleSearchAPI();

        let suggestions = this.state.symptoms.length > 0 && this.state.symptoms.map((suggestion)=>{
            return (!tags.find(item => item.name === suggestion.name)) ? suggestion : {};
        });

        this.setState({ tags, diseaseData, suggestions });
    }

    /**
     * load Diseases
     */
    loadDiseases(){
        this.setState((prev) => {
            return { diseasesVisible: prev.diseasesVisible + DISEASES_VISIBLE };
        });
    }
    /**
     * search the graphql api
     * by default always return diseases
     * @param query
     * @param action
     */
    handleSearchAPI (query = null, action = "") {

        switch(action) {

            // GET SYMPTOMS BASED ON SELECTED ONES
            case "symptoms":

                this.setState({
                    loading:true
                });

                let qry = JSON.stringify(this.getLatestSearch(query)).replace(/(^|[^\\])"/g, '$1\\\"');
                // db.cache.reset();

                db.query({
                        query: gql`
                            {
                              diseasesBySymptoms(query:"${qry}"){
                                id
                                name
                                description
                                uri
                                score
                              }
                            }
                        `
                    })
                    .then((response)=>{

                        let diseases = response.data.diseasesBySymptoms;
                        diseases.sort((a,b) => b.score - a.score);

                        sessionStorage.setItem("tags", JSON.stringify(query));

                        this.setState({
                            error:false,
                            currentId: diseases[0].id,
                            diseases:diseases,
                            lastQuery: query,
                            diseasesVisible:DISEASES_VISIBLE,
                            loading:false
                        });

                        this.handleDiseaseContent(diseases[0].uri);

                    })
                    .catch(error => {
                        this.setState({
                            error:true,
                            errorMessage: "Can't fetch data [symptoms]"
                        });
                    });

                break;

            // SEARCH IN SYMPTOM
            case "search":
                // let searchQuery = query.replace(/[^\w\s]/gi, '');
                // db.cache.reset();
                db.query({
                        query: gql`
                        {
                            allSymptoms {
                                name
                                description
                                id   
                            }
                           diseases {
                                name
                                description
                                id   
                            }
                        }
                    `
                    })
                    .then((response)=>{

                        let data = response.data.allSymptoms;

                        let symptoms = data;

                        let suggestions = data.length > 0 && data.map((suggestion)=>{
                            return (!this.state.tags.find(item => item.name === suggestion.name)) ? suggestion : {};
                        });

                        let test = suggestions.filter((obj)=>{
                            let regexp = this.matchPartial(query);
                            return regexp.test(obj.name);
                        });

                        let loading = false;
                        let suggestion = test.length >= 1;

                        this.setState({
                            symptoms,
                            suggestions,
                            loading,
                            suggestion
                        });

                    })
                    .catch(error => {
                        this.setState({
                            error:true,
                            errorMessage: "Can't fetch data [search]"
                        });
                    });

                break;

            // DEFAULT
            default:

                this.setState({loading:true});

                // db.cache.reset();
                db.query({
                    query: gql`
                        {
                            diseases {
                                id
                                name
                                description
                                uri
                                score
                            }
                        }
                    `
                })
                .then( (response) => {
                    let diseases = response.data.diseases;
                    diseases.sort((a,b) => b.score - a.score);

                    this.setState({ currentId: diseases[0].id, loading:false,  diseasesVisible:DISEASES_VISIBLE });

                })
                .catch(error => {
                    this.setState({
                        error:true,
                        errorMessage: "Can't fetch data [diseases]"
                    });
                });

                break;

        }

    }

    /**
     * get latest tags to prefill data
     * @param query
     * @returns {*}
     */
    getLatestSearch(query) {

        let tags = JSON.parse(sessionStorage.getItem("tags"));
        if(tags > 0) {
            return tags;
        } else {
            sessionStorage.removeItem("tags");
        }

        return query;

    }

    matchPartial(query) {
        let q = query.replace(/[°"§%()\[\]{}=\\?´`'#<>|,;.:+_-]+/g, '');
        return new RegExp(`(?:^|\\s)${q}`, 'gi')
    }

    /**
     * handleDiseaseContent
     * once a disease is clicked, get page data from grav to show summary
     * @param route
     */
    handleDiseaseContent = (route) => {

        const {parentUrl} = this.props;

        let uri = parentUrl + route;

        // get page content via grav route
        grav.query({
                query: gql`
                  {
                      page(route: "${uri}") {
                        title
                        content
                        route
                        children {
                            title
                            content
                            route
                        }
                        metadata {
                          content
                        }
                      }
                    }
                `
            })
            .then((result)=>{
                this.handleDiseaseResponse(result.data)
            });
    };

    /**
     * handleDiseaseResponse
     * @param data
     */
    handleDiseaseResponse = (data) => {

        if (data.page != null) {

            let obj = {
                page: data.page,
                summary: data.page.children[0]
            };
            this.setState({
                diseaseData: obj,
                loading: false
            });

        }
    };

    /**
     * shuffle
     * @param arr
     * @returns {*}
     */
    shuffle = (arr) => {
        for (let i = arr.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [arr[i], arr[j]] = [arr[j], arr[i]];
        }
        return arr;
    };

    render() {

        const {searchUrl, diseasesUrl, pages, isMobile} = this.props;
        const {error, errorMessage, suggestions, tags, diseases, showModal, suggestion, diseasesBySymptoms} = this.state;

        return <div className={`symptom-tags-home__search ${(this.state.focused) ? "is-focused" : ""}`}>
                <Row>
                    <Col xs={12} lg={12}>

                        { error &&  <MessageContainer message={errorMessage}/> }

                        {/* mobile */}
                        { isMobile && <MobilePopup
                                title={'Zoek op symptoom'}
                                isMobile={isMobile}
                                open={showModal}
                                close={()=>this.setState({showModal : false})}
                            >
                            <ReactTags tags={ this.state.tags }
                                       suggestionsFilter={(item, query)=>{
                                           let name = item.name;
                                           let regexp = this.matchPartial(query.trim());
                                           return (item.disabled) ? true : regexp.test(name);
                                       }}
                                       classNames={TAGS_CLASS_NAMES}
                                       allowBackspace={false}
                                       focused={true}
                                       autoresize={false}
                                       minQueryLength={1}
                                       suggestions={(suggestion) ? this.state.suggestions : [{id:1, name:'Geen resultaat gevonden', disabled:true}]}
                                       onDelete={this.onDelete.bind(this)}
                                       onAddition={this.onAddition.bind(this)}
                                       onInput={(query)=>{
                                           let test = suggestions.filter((obj)=>{
                                               let regexp = this.matchPartial(query.trim());
                                               return regexp.test(obj.name);
                                           });
                                           this.setState({
                                               suggestion: test.length >= 1
                                           });
                                       }}
                                       onFocus={()=>{
                                       }}
                                       placeholderText={"Zoek op symptoom"}
                                       removeButtonText={"Symptoom verwijderen"}
                            />
                            <Link to={searchUrl} className="btn secondary-btn btn-block" onClick={()=>{this.setState({showModal : false})}}>
                                { (this.state.loading) ?
                                    <Spinner size="sm" animation="border" /> :
                                    <span className="label-bold">Toon {diseases.length} {(diseasesBySymptoms.length > 1) ? "resultaten" : "resultaat"}</span>
                                }
                            </Link>
                            </MobilePopup> }

                            {/* desktop */}
                            <div className={`symptom-tags__search-wrapper ${(!suggestion) ? 'symptom-tags__no-results' : ''}`}>
                            { !isMobile &&

                            <ReactTags tags={ this.state.tags }
                                       suggestionsFilter={(item, query)=>{
                                           let name = item.name;
                                           let regexp = this.matchPartial(query);
                                           return (item.disabled) ? true : regexp.test(name);
                                       }}
                                       classNames={TAGS_CLASS_NAMES}
                                       allowBackspace={true}
                                       focused={true}
                                       autoresize={false}
                                       minQueryLength={1}
                                       suggestions={(this.state.suggestion) ? suggestions : [{id:1, name:'Geen resultaat gevonden', disabled:true}]}
                                       onDelete={this.onDelete.bind(this)}
                                       onAddition={this.onAddition.bind(this)}
                                       onInput={(query)=>{

                                           console.log(query);

                                           let test = suggestions.filter((obj)=>{
                                               let regexp = this.matchPartial(query);
                                               return regexp.test(obj.name);
                                           });
                                           console.log(test);
                                           let suggestion = test.length >= 1;

                                           this.setState({
                                               suggestion
                                           });
                                       }}
                                       onPaste={(e)=>{
                                           console.log(e.target.value);
                                       }}
                                       placeholderText={(tags.length >= 1) ? "Voeg symptoom toe" : "Zoek op symptoom"}
                                       removeButtonText={"Symptoom verwijderen"}
                            />
                            }
                            { !isMobile && <div className="symptom-tags__search-btnWrapper">
                                { this.state.loading && <Spinner animation="border" size="sm" /> }
                                { tags.length >= 1 && <Link to={searchUrl} state={{isHomeRequest:true}} className="btn btn-primary btn-lg">
                                    Zoeken
                                </Link>}
                            </div> }

                            {/* mobile */}
                            { isMobile && <div className="symptom-tags">
                                <div className="symptom-tags__search">
                                <input
                                placeholder="Zoek op symptoom"
                                className="symptom-tags__search-input-btn"
                                onClick={()=>{
                                    this.setState({showModal : true}, ()=>{
                                        document.getElementsByClassName("symptom-tags__search-input")[0].focus();
                                        setTimeout(()=>{
                                            window.scroll(0, 0);
                                        },300);
                                    });
                                }}
                               />
                                </div>
                            </div>
                            }
                            </div>
                            <div className="homePage__diseasesOverview">
                                <p className="homePage__diseasesOverview_title">of selecteer een ziektebeeld</p>
                                <ResponsiveContainer>
                                    { diseasesBySymptoms && <RenderDiseasesList diseases={diseasesBySymptoms} parentUrl={diseasesUrl} isMobile={isMobile} /> }
                                </ResponsiveContainer>
                            </div>
                    </Col>
                </Row>
        </div>;
    }
}
export default TagSearchHome;
